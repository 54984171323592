<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0"> 
            {{ $t("message.new_m", { m: $t("message.additional_fee_of_product"), }) }}
          </p>
          <div>
             <crm-store-update-close
                :permission="$options.name"
                :button_type="'store'"
                :loading="loadingButton"
                @c-submit="submit(true)"
                @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
            <el-form ref="form" :model="form" :rules="rules" label-position="top">
                <el-row :gutter="20">

                    <el-col :span="24">
                      <el-form-item
                          prop="name" 
                          :label="$t('message.name')"
                        >
                            <el-input
                                :placeholder="$t('message.name')"
                                v-model="form.name"
                                size="medium"
                            ></el-input>
                       </el-form-item>     
                    </el-col>
                    <!-- end-col -->

                    <el-col :span="6">
                      <el-form-item
                          prop="percentage" 
                          :label="$t('message.percentage')"
                        >
                            <el-input
                                :placeholder="$t('message.percentage')"
                                v-model="form.percentage"
                                type="number"
                                size="medium"
                            ></el-input>
                       </el-form-item>     
                    </el-col>
                    <!-- end-col -->

                    <el-col :span="6">
                      <el-form-item
                          prop="money_amount" 
                          :label="$t('message.money_amount')"
                        >
                            <el-input
                              :placeholder="$t('message.money_amount')"
                              type="number"
                              v-model="form.money_amount"
                              size="medium"
                            ></el-input>
                       </el-form-item>     
                    </el-col>
                    <!-- end-col -->

                    <el-col :span="6">
                      <el-form-item
                          prop="currency_id" 
                          :label="$t('message.currency_id')"
                        >
                        <select-currency
                            :size="'medium'"
                            :placeholder="$t('message.currency')"
                            :id="form.currency_id"
                            v-model="form.currency_id"
                          >
                          </select-currency>
                       </el-form-item>     
                    </el-col>
                    <!-- end-col -->
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="6">
                      <el-form-item
                          prop="calculation_by" 
                          :label="$t('message.calculation_by')"
                        >
                        <el-switch
                          v-model="form.calculation_by"
                          :active-value="CALCULATION_BY_MONEY"
                          :inactive-value="CALCULATION_BY_PERCENTAGE"
                          :active-text="$t('message.money_amount')"
                          :inactive-text="$t('message.percentage')">
                        </el-switch>
                       </el-form-item>     
                    </el-col>
                    <!-- end-col -->
                
                    <el-col :span="6">
                      <el-form-item
                          prop="active" 
                          :label="$t('message.active')"
                        >
                        <el-switch
                          v-model="form.active"
                          :active-text="$t('message.active')"
                          :inactive-text="$t('message.inactive')">
                        </el-switch>
                       </el-form-item>     
                    </el-col>
                    <!-- end-col -->
                </el-row>
            </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import selectCurrency from "@/components/inventory/select-currency";

export default {
  mixins: [form, drawer],
  components: {
    selectCurrency,
  },
  data() {
    return {
      CALCULATION_BY_PERCENTAGE: 1,
      CALCULATION_BY_MONEY: 2,
    };
  },
  created() {},
  computed: {
      ...mapGetters({
          rules: "dealProductAdditionalFeeType/rules",
          model: "dealProductAdditionalFeeType/model",
          columns: "dealProductAdditionalFeeType/columns",
      }),
  },
  methods: {
      ...mapActions({
          save: "dealProductAdditionalFeeType/store",
      }),
      submit(close = true) {
          this.$refs["form"].validate((valid) => {
              if (valid) {
                  this.loadingButton = true;
                  this.save(this.form)
                      .then((res) => {
                          this.loadingButton = false;
                          this.$alert(res);
                          this.parent().listChanged();
                          if (close) this.close();
                      })
                      .catch((err) => {
                          this.loadingButton = false;
                          this.$alert(err);
                      });
              }
          });
      },
  },
};
</script>
